import Helpers from "../utils/helpers";

export const GET_COMMENTS_BY_ID = (id) => `https://jsonplaceholder.typicode.com/posts/${id}/comments`;

// ACQUIRERS
export const LIST_ACQUIRERS = () => {
  let result = `${process.env.REACT_APP_GET_ACQUIRER_LIST}`
  return result;  
}

// MERCHANTS
export const GET_MERCHANT = (id) => {
  let result = `${process.env.REACT_APP_GET_MERCHANT}/${id}`;
  return result;
}

export const LIST_MERCHANTS = (filters, page, filterSelectedToDelete) => {
  let result = `${process.env.REACT_APP_GET_MERCHANT_LIST}?page=${page}&size=10`;
  if (filterSelectedToDelete) {
    let lastUrl = localStorage.getItem("urlMerchants");
    // eslint-disable-next-line default-case
      if (filterSelectedToDelete === 'Fecha de inicio') {
        let format = filters.fromDate.split("/")
        format = format.reverse();
        format = format.join("-")
        lastUrl = lastUrl.replace(`&date_start=${format}`,'')
      }
      if (filterSelectedToDelete === 'Fecha de fin') {
        let format = filters.fromDate.split("/")
        format = format.reverse();
        format = format.join("-")
        lastUrl = lastUrl.replace(`&date_end=${format}`,'')
      }
      if (filterSelectedToDelete === 'Nombre de comercio') {
        // filters.authResultQuery = filters.authResultQuery.replace("ó", "o")
        lastUrl = lastUrl.replace(`&merchant_name=${filters.merchantName}`,'')
      }
      if (filterSelectedToDelete === 'Código de comercio') {
        lastUrl = lastUrl.replace(`&merchant_key=${filters.merchantCode}`,'')
      }
      if (filterSelectedToDelete === 'Estado') {
        filters.state = (filters.state === "Afiliado") ? 'enabled' : 'disabled'
        lastUrl = lastUrl.replace(`&state=${filters.state}`,'')
      }
      if (filterSelectedToDelete === 'Marca') {
        lastUrl = lastUrl.replace(`&brand=${filters.brand}`,'')
      }
    localStorage.setItem("urlMerchants", lastUrl)
    return lastUrl;
  } else {
    if (filters.fromDate) result = result + '&date_start=' + filters.fromDate
    if (filters.toDate) result = result + '&date_end=' + filters.toDate
    if (filters.merchantName) result = result + '&merchant_name=' + filters.merchantName
    if (filters.merchantCode) result = result + '&merchant_key=' + filters.merchantCode
    if (filters.sort && !filters.sort.includes('none_')) {
      result = result + '&sort=' + filters.sort
    }
    if (filters.state) {
      let str = ""
      str = (filters.state === "Deshabilitado") ? "disabled" : "enabled"
      result = result + '&state=' + str
    }
    if (filters.brand) result = result + '&brand=' + filters.brand
  
    if (result.indexOf("?&") !== -1) {
      result = result.replace("?&", "?")
    }
  }
  localStorage.setItem("urlMerchants", result)
  return result;
}

// export const REGISTER_MERCHANT = "https://38l1cmgf5m.execute-api.us-east-1.amazonaws.com/development/merchants";

export const PUT_PROCESS_FILE = () => '';

// TRANSACTIONS
export const GET_INFO_TRANSACTION = (id) => {
  let result = `${process.env.REACT_APP_GET_TRANSACTION}/${id}`;
  return result;
}

export const GET_LIST_TRANSACTIONS = (filters, page, filterSelectedToDelete) => {
  let result = `${process.env.REACT_APP_GET_TRANSACTION_LIST}?page=${page}&size=20`
  if (filterSelectedToDelete) {
    let lastUrl = localStorage.getItem("urlQueries");
    // eslint-disable-next-line default-case
      if (filterSelectedToDelete === 'Fecha de inicio') {
        let format = filters.fromDateQuery.split("/")
        format = format.reverse();
        format = format.join("-")
        lastUrl = lastUrl.replace(`&date_start=${format}`,'')
      }
      if (filterSelectedToDelete === 'Fecha de fin') {
        let format = filters.toDateQuery.split("/")
        format = format.reverse();
        format = format.join("-")
        lastUrl = lastUrl.replace(`&date_end=${format}`,'')
      }

      if(filterSelectedToDelete === 'Tipo de transacción') {
        lastUrl = lastUrl.replace(`&transaction_type=${filters.transactionTypeQuery}`,'')
      }

      if (filterSelectedToDelete === 'Resultado de autenticación') {
        filters.authResultQuery = filters.authResultQuery.toLowerCase()
        filters.authResultQuery = filters.authResultQuery.replace(" ", "_").replace(" ", "_").replace(" ", "_")
        filters.authResultQuery = filters.authResultQuery.replace("ó", "o")
        filters.authResultQuery = filters.authResultQuery.replace("á", "a")
        // filters.authResultQuery = filters.authResultQuery.replace("ó", "o")
        lastUrl = lastUrl.replace(`&authentication=${filters.authResultQuery}`,'')
      }
      if (filterSelectedToDelete === 'Nombre de comercio') {
        lastUrl = lastUrl.replace(`&commerce_name=${filters.nameQuery}`,'')
      }
      if (filterSelectedToDelete === 'Código de comercio') {
        lastUrl = lastUrl.replace(`&commerce=${filters.codeQuery}`,'')
      }
      if (filterSelectedToDelete === 'Número de pedido') {
        lastUrl = lastUrl.replace(`&number_orde=${filters.numberOrderQuery}`,'')
      }
      if (filterSelectedToDelete === 'Marca') {
        lastUrl = lastUrl.replace(`&brand=${filters.brandQuery}`,'')
      }
      if (filterSelectedToDelete === 'Número de tarjeta') {
        lastUrl = lastUrl.replace(`&number_card=${Helpers.withSHA512(filters.cardNumberQuery)}`,'')
      }
      if (filterSelectedToDelete === 'Enmascarado') {
        lastUrl = lastUrl.replace(`&pan_search_type=${filters.maskTypeQuery}`,'')
      }
      if (filterSelectedToDelete === 'En claro') {
        lastUrl = lastUrl.replace(`&pan_search_type=${filters.inclearTypeQuery}`,'')
      }
      if (filterSelectedToDelete === 'BIN') {
        lastUrl = lastUrl.replace(`&bin=${filters.binQuery}`,'')
      }
      if (filterSelectedToDelete === 'LAST PAN') {
        lastUrl = lastUrl.replace(`&last_pan=${filters.lastPanQuery}`,'')
      }
      if (filterSelectedToDelete === 'Moneda') {
        lastUrl = lastUrl.replace(`&purchaseCurrency=${filters.currencyQuery}`,'')
      }
      if (filterSelectedToDelete === 'ECI') {
        lastUrl = lastUrl.replace(`&eci=${filters.eciQuery}`,'')
      }

    localStorage.setItem("urlQueries", lastUrl)
    return lastUrl;
  }
  else {
    if (filters) {
      // if (result.search("&pan_search_type=") !== -1) {
      //   result.replace("&pan_search_type=", "")
      // }
      if (filters.fromDateQuery) result = result + '&date_start=' + filters.fromDateQuery
      if (filters.toDateQuery) result = result + '&date_end=' + filters.toDateQuery
      if (filters.transactionTypeQuery && filters.transactionTypeQuery !== 'Todos') result = result + '&transaction_type=' + filters.transactionTypeQuery
      if (filters.authResultQuery && filters.authResultQuery !== "Todos") {
        filters.authResultQuery = filters.authResultQuery.replace("ó", "o")
        filters.authResultQuery = filters.authResultQuery.replace("á", "a")
        result = result + '&authentication=' + filters.authResultQuery.replace(" ", "_").replace(" ", "_").replace(" ", "_").toLowerCase()
      }
      if (filters.nameQuery) result = result + '&commerce_name=' + filters.nameQuery
      if (filters.codeQuery) result = result + '&commerce=' + filters.codeQuery
      if (filters.numberOrderQuery) result = result + '&number_orde=' + filters.numberOrderQuery
      if (filters.brandQuery) result = result + '&brand=' + filters.brandQuery 
      if (filters.cardNumberQuery) result = result + '&number_card=' + Helpers.withSHA512(filters.cardNumberQuery)
      if (filters.binQuery) result = result + '&bin=' + filters.binQuery
      if (filters.currencyQuery && filters.currencyQuery !== "Todos") result = result + '&purchaseCurrency=' + filters.currencyQuery
      if (filters.eciQuery) result = result + '&eci=' + filters.eciQuery
      if (filters.lastPanQuery) result = result + '&last_pan=' + filters.lastPanQuery
      if (filters.inclearTypeQuery) {
        if (filters.maskTypeQuery) filters.maskTypeQuery = ""
        result = result + '&pan_search_type=' + filters.inclearTypeQuery
      }
      if (filters.maskTypeQuery) {
        if (filters.inclearTypeQuery) filters.inclearTypeQuery = ""
        result = result + '&pan_search_type=' + filters.maskTypeQuery
      }
      if (filters.sort && !filters.sort.includes('none_')) {
        result = result + '&sort=' + filters.sort
      }
     }
    if (result.indexOf("?&") !== -1) {
      result = result.replace("?&", "?")
    }
  }
  localStorage.setItem("urlQueries", result)
  return result 
}

export const EDIT_MERCHANT = (key) => {
  let result = `${process.env.REACT_APP_PUT_MERCHANT}/${key}`
  return result;
}

// CONFIGURATION
export const LIST_BRANDS = (id) => {
  let result = `${process.env.REACT_APP_GET_ACQUIRER}/${id}`;
  return result;
}

export const LIST_COUNTRIES = `${process.env.REACT_APP_GET_CONFIGURATION}`;

// BULK LOAD
export const GET_URL_DOWNLOAD_FILE = (id) => {
  let result = `${process.env.REACT_APP_GET_MERCHANT_BATCH}/${id}`
  return result;
}

export const GET_FILE = (id) => `dasdasd`

export const POST_UPLOAD_FILE = "https://jsonplaceholder.typicode.com/posts";

export const SENT_FILE_TO_SERVER = () => {
  let result = `${process.env.REACT_APP_POST_UPLOAD_FILE_TO_S3}`
  return result;
}

export const PUT_PROCESS_FILE_ROUTE = () => {
  let result = `${process.env.REACT_APP_GET_MERCHANT_IMPORT}`
  return result;
}

// export const GET_URL_UPLOAD_FILE = "https://aa5vor4ca2.execute-api.us-east-1.amazonaws.com/development/merchants/batch";

export const GET_SENT_ALL_FILTERS = (dateStart, dateEnd, name, processBatch, stateBatch, page) => {
  let url = `${process.env.REACT_APP_GET_MERCHANT_BATCH_LIST}?page=${page}&size=10`;
  let newUrl = `${url}${((name) && !dateStart && !dateEnd) ? `&` : ``}${(dateStart && dateEnd) && `&date_start_search=${dateStart}&date_end_search=${dateEnd}`}${((dateStart && dateEnd)) ? `&` : ``}${name && `upload_name_batch=${name}`}${((processBatch || (dateStart && dateEnd))) ? `&` : ``}${processBatch && `state_process_batch=${processBatch}`}${stateBatch && `state_enable_batch=${stateBatch}`}`
  let arrayUrl = newUrl.split("")
  let result = ''

  if (newUrl.indexOf("&&") !== -1) {
    result = newUrl.replace("&&", "")
  }

  if (newUrl.indexOf("?&") !== -1) {
    result = newUrl.replace("?&", "?")
  }
  if (result.split("")[result.split("").length-1] === '&') {
    newUrl = newUrl.split("")
    newUrl = newUrl.splice(arrayUrl.length-1, 1);
    result = newUrl.join("")
  }
  if (!result) result = newUrl
  return result
}

// USERS
export const LIST_USERS = (filters, pageCurrentDashboard) => {
  let result = `${process.env.REACT_APP_GET_USER_LIST}?page=${pageCurrentDashboard}&size=10`;
  if (filters) {
    if (filters.fromDateUser) {
      // filters.fromDateUser = filters.fromDateUser.replace('/', '-').replace('/', '-')
      result = result + '&date_start=' + filters.fromDateUser
    }
    if (filters.toDateUser) {

      // filters.toDateUser = filters.toDateUser.replace('/', '-').replace('/', '-')
      // filters.toDateUser = filters.toDateUser.split("-")
      // let newFormatArray = filters.toDateUser
      // const aux = newFormatArray.reverse();
      // const aux2 = aux.join("-")
      result = result + '&date_end=' + filters.toDateUser
    }
    if (filters.name) {
      filters.state = filters.state.replace(' ', '_')
      filters.name = filters.name.replace("ó", "o")
      filters.name = filters.name.replace("á", "a")
      filters.name = filters.name.replace("í", "i")
      result = result + '&name=' + filters.name
    }
    if (filters.lastName) result = result + '&lastname=' + filters.lastName
    if (filters.email) result = result + '&email=' + filters.email
    if (filters.role) result = result + '&role=' + filters.role.toLowerCase()
    if (filters.state) {
      filters.state = filters.state.replace(' ', '_')
      filters.state = filters.state.replace("ó", "o")
      filters.state = filters.state.replace("á", "a")
      filters.state = filters.state.replace("í", "i")
      result = result + '&status=' + filters.state.toLowerCase()
    }
    if (filters.sort && !filters.sort.includes('none_')) {
      result = result + '&sort=' + filters.sort
    }
  }
  // if (filterSelectedToDelete === 'Nombre de comercio') {
  //   lastUrl = lastUrl.replace(`&commerce_name=${filters.nameQuery}`,'')
  // }
  localStorage.setItem("urlUsers", result)
  return result;
}

export const GET_INFO_USER = (id) => {
  let result = `${process.env.REACT_APP_GET_USER}/${id}`;
  return result;
}

export const GET_ASSOCIATE_DETAIL  = (id, pageCurrent) => {
  let result = `${process.env.REACT_APP_GET_ASSOCIATION_DETAIL}/${id}?page=${pageCurrent}&size=10`;
  return result;
}

// SENT INVITATION
export const GENERATE_INVITATION = () => {
  let result = `${process.env.REACT_APP_POST_INVITATION}`
  return result;
}

// RESENT CODE
export const RESENT_CODE = () => {
  let result = `${process.env.REACT_APP_POST_FORWARDING_OTP}`
  return result;
}

// CANCEL INVITATION
export const CANCEL_INVITATION = (id) => {
  let result = `${process.env.REACT_APP_PUT_USER}/${id}`;
  return result;
}

export const UPDATE_USER_INFO = (id) => {
  let result = `${process.env.REACT_APP_PUT_USER}/${id}`;
  return result;
}

export const RESENT_INVITATION = (id) => {
  let result = `${process.env.REACT_APP_PUT_USER}/${id}`;
  return result;
}

export const DELETE_USER = (id) => {
  let result = `${process.env.REACT_APP_PUT_USER}/${id}`;
  return result;
}
export const UPDATE_USER_IMAGE = (id) => {
  let result = `${process.env.REACT_APP_PUT_USER}/${id}`;
  return result;
}

export const PUT_ACQUIRER_IMAGE = (id) => {
  let result = `${process.env.REACT_APP_PUT_ACQUIRER}/${id}`;
  return result;
}
