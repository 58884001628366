import { TRANSACTION_ACTIONS } from "../../constants/actions"

export const sentFiltersListMerchants = (filters, token, page, acquirerSelected) => {
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_SENT_FILTERS_LIST_TRANSACTIONS,
      filters,
      token,
      page,
      acquirerSelected
  }
}

export const sentFiltersListTransactionsByNumber = (filters, token, page, acquirerSelected) => {
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GO_TO_PAGE,
      filters,
      token,
      page,
      acquirerSelected
  }
}

export const receiveResponseSentFiltersListMerchants = (response) => {
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RECEIVE_RESPONSE_SENT_FILTERS_LIST_TRANSACTIONS,
    response
  }
}
export const getInfoTransaction = (id, token, acquirerSelected) => {
  console.log(id)
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GET_INFO,
      id,
      token,
      acquirerSelected
  }
}

export const receiveResponseGetInfoTransaction = (response) => {
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RECEIVE_RESPONSE_GET_INFO,
    response
  }
}

export const nextPage = () => {
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_NEXT_PAGE
  }
}

export const goToPage = (page) => {
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GO_TO_PAGE,
      page
  }
}

export const backPage = () => {
  return {
      type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_BACK_PAGE
  }
}

export const resetFilterValues = () => {
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_FILTERS_RESET
  }
}

export const handleObjFiltered = (id, value) => {
  console.log(value)
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_HANDLE_FIELDS_FILTEREDS,
    id,
    value
  }
}

export const deleteFilterSelected = (filters, token, page, acquirerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    acquirerSelected,
    filterSelectedToDelete,
    propertyName
  }
}


export const changeOrder = (field, direction, token, acquirerSelected, page, filters) => {
  return {
    type: TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    acquirerSelected,
    page,
    filters
  }
}