import { RESET_OBJ_MERCHANTS } from "../constants/constants";
import { AUTH_USER } from "../constants/routes";

class Transform {

  static encryptNumber(number) {
    let result = '';

    if (number) {
      number = number.toString();
      const firstPart = number.slice(0, 7)
      const secondPart = number.slice(12, 16)
      result = firstPart + '*****' + secondPart
    }
    return result;
  }

  static modelToRequestBulkLoadList(filters) {
    let result = [];
    if (filters) {
      if (filters.fromDate) {
        let convertedDate = filters.fromDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        result.push({name: 'date_start', value: convertedDate})
      }
      if (filters.toDate) {
        let convertedDate = filters.toDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        result.push({name: 'date_end', value: convertedDate})
      }
      if (filters.fileName) result.push({name: 'name_upload', value: filters.fileName})
      if (filters.stateBatch) result.push({name: 'state_batch', value: (filters.stateBatch === "Afiliado") ? "enabled": "disabled"})
      if (filters.stateProcess) result.push({name: 'state_process', value: filters.stateProcess})
    }
    return result;
  }

  static modelToRequestMerchantsList(filters) {
    if (filters) {
      console.log(filters)
      if (filters.fromDate) {
        let convertedDate = filters.fromDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDate = convertedDate;
      }
      if (filters.toDate) {
        let convertedDate = filters.toDate.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDate = convertedDate;
      }
      // if (filters.merchantName) filters.merchantName = 
      // if (filters.merchantCode) result.push({name: 'merchant_key"', value: filters.merchantCode})
      
      if (filters.state) filters.state = (filters.state === 'Afiliado') ? 'Habilitado' : 'Deshabilitado'
      if (filters.brand) filters.brands = filters.brand.toUpperCase()
    }
    return filters;
  }

  static modelToRequestUsersList(filters) {
    if (filters) {
      console.log(filters)
      if (filters.fromDateUser) {
        let convertedDate = filters.fromDateUser.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.fromDateUser = convertedDate;
      }
      if (filters.toDateUser) {
        let convertedDate = filters.toDateUser.split("/")
        convertedDate = convertedDate.reverse()
        convertedDate = convertedDate.join("-")
        filters.toDateUser = convertedDate;
      }
      // if (filters.merchantName) filters.merchantName = 
      // if (filters.merchantCode) result.push({name: 'merchant_key"', value: filters.merchantCode})
      
      // if (filters.state) filters.state = (filters.state === 'Afiliado') ? 'Habilitado' : 'Deshabilitado'
      // if (filters.brand) filters.brands = filters.brand.toUpperCase()
    }
    return filters;
  }

  static modelToQueryFilters(filters) {
    let result = {};
    // console.log(filters)
    if (filters) {
       if (filters.fromDateQuery) {
         let convertedDate = filters.fromDateQuery.split("/")
         convertedDate = convertedDate.reverse()
         convertedDate = convertedDate.join("-")
        //  result.push({name: 'date_start', value: convertedDate})
        filters.fromDateQuery = convertedDate
       }
      //  console.log(filters)
       if (filters.toDateQuery) {
         let convertedDate = filters.toDateQuery.split("/")
         convertedDate = convertedDate.reverse()
         convertedDate = convertedDate.join("-")
         filters.toDateQuery = convertedDate
        //  result.push({name: 'date_end', value: convertedDate})
       }
      if (!filters.transactionTypeQuery) filters.transactionTypeQuery = 'Todos'
      if (!filters.authResultQuery) filters.authResultQuery = 'Todos'
      if (!filters.maskTypeQuery) filters.maskTypeQuery = 'Enmascarada'
      if (!filters.currencyQuery) filters.currencyQuery = 'Todos'
      }
      // console.log(filters)
      result = filters
     return result;
   }

  static validateDataTableQueries(listResponse) {
    // console.log(listResponse)
    let result = [];
    let obj = {
      id: {
        value:'',
        hidden:true
      },
      name: {
        value:'',
      },
      key: {
        value: ''
      },
      numberOrder:{
        value:'',
        type:'link',
        link: ''
      },
      cardNumber:{
        value:'',
        typeCard: ''
      },
      version:{
        value:''
      },
      amount:{
        value:''
      },
      result:{
        value: ''
      },
      eci:{
        value:''
      },
      creationDate:{
        value:''
      }
    }
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
       listResponse.forEach((element, index)=> {
        if (obj && obj.id) obj.id.value = element.commerce;
        if (obj && obj.name) obj.name.value = element.commerce_name;
        if (obj && obj.key) {
          obj.key.value = element.commerce;
        }
        if (obj && obj.numberOrder) {
          obj.numberOrder.link = `query/detail/${element.key}`
          obj.numberOrder.value = element.number_order;
          
        }
        if (obj && obj.cardNumber) {
          obj.cardNumber.value = element.number_card.number;
          obj.cardNumber.typeCard = element.number_card.type;
        }
        if (obj && obj.version) obj.version.value = element.version;
        if (obj && obj.eci) obj.eci.value = element.eci;
        if (obj && obj.result) obj.result.value = element.result
        if (obj && obj.amount) obj.amount.value = `${element.import || ''}`;
        if (obj && obj.creationDate) obj.creationDate.value = element.creationDate;
        // console.log(obj)
        result.push(obj);
        obj = {
          id: {
            value:'',
            hidden:true
          },
          name: {
            value:'',
          },
          key: {
            value: ''
          },
          numberOrder:{
            value:'',
            type:'link',
            link: ''
          },
          cardNumber:{
            value:'',
            typeCard: ''
          },
          version:{
            value:''
          },
          amount:{
            value:''
          },
          result:{
            value: ''
          },
          eci:{
            value:''
          },
          creationDate:{
            value:''
          }
        }
        return obj
    })
    return result;
  }
}

  static validateDataTableMerchants(listResponse) {
    let result = [];
    let obj = {
      id: {
        value:'',
        hidden:true
      },
      name: {
        value:'',
        type:'link_commerce',
        link: ''
      },
      // code:{
      //   value:'',
      // },
      status:{
        value:''
      },
      brand:{
        value:''
      },
      date:{
        value:''
      }
    };
    if (listResponse) {
       listResponse.forEach((element, index)=> {
         if (element && element.key) {
          if (obj && obj.id) obj.id.value = element.commerce;
          if (obj && obj.name) {            
            obj.name.value = element.commerce_name;
            obj.name.link = `merchant/edit/${element.key}`
          }
          if (obj && obj.code) {
            obj.code.value = element.commerce;
            obj.code.link = `merchant/edit/${element.key}`
          }
          if (obj && obj.status) obj.status.value = element.enabled ? 'Afiliado' : 'Desafiliado';
          if (obj && obj.brand) {
            const aux = element.brands.map(e => {
              if (e && e === 'DINC') e = 'Diners Club'
              if (e && e === 'MSCD') e = 'Mastercard'
              if (e && e === 'AMEX') e = 'American Express'
              if (e && e === 'VISA') e = 'Visa'
              return e;
              })
            obj.brand.value = aux.join(", ");
          }
          if (obj && obj.date) obj.date.value = element.created_at;
          result.push(obj);
          obj = {
            id: {
              value:'',
              hidden:true
            },
            name: {
              value:'',
              type:'link_commerce',
              link: 'merchant/edit/'
            },
            // code:{
            //   value: element.key,
            // },
            status:{
              value:''
            },
            brand:{
              value:''
            },
            date:{
              value:''
            }
        }
          return obj
         }
    })
    return result;
  }
}

static validateDataTableBulkLoadFiles(listResponse) {
    let result = [];
    let obj = {
      name: {
        value:'',
      },
      recordsReceived:{
        value:'',
        // type:'link',
        // link:'home/merchant/edit/123456'
      },
      recordsProcessed:{
        value:''
      },
      processedFile:{value:'', type: 'download', link: 'google.com.pe', name: 'archivo1.csv'},
      processState:{
        value:''
      },
      dateUploaded: {value: ''},
      id: {
        value:'',
        hidden:false
      },
      batchId: {
        value:'',
        hidden:true
      }
    };
    if (listResponse) {
      console.log(listResponse)
      console.log(obj)
      listResponse.forEach((element, index)=> {
        if (obj && obj.id) obj.id.value = element.user_id;
        if (obj && obj.name) obj.name.value = element.upload_name_batch;
        if (obj && obj.recordsReceived) obj.recordsReceived.value = element.records_received;
        if (obj && obj.recordsProcessed) obj.recordsProcessed.value = element.records_processed;
        if (obj && obj.processState) obj.processState.value = element.state_process_batch;
        if (obj && obj.processedFile) obj.processedFile.value = element.generate_name_batch;
        if (obj && obj.dateUploaded) obj.dateUploaded.value = element.date_time_upload_batch;
        if (obj && obj.batchId) obj.batchId.value = element.merchant_batch_id;
        console.log(obj)
        result.push(obj);
        obj = {
          name: {
            value:'',
          },
          recordsReceived:{
            value:'',
            // type:'link',
            // link:'home/merchant/edit/123456'
          },
          recordsProcessed:{
            value:''
          },
          processedFile:{value:'', type: 'download', link: 'google.com.pe', name: 'archivo1.csv'},
          processState:{
            value:''
          },
          dateUploaded: {value: ''},
          id: {
            value:'',
            hidden:false
          },
          batchId: {
            value:'',
            hidden:true
          }
      }
        return obj
    })
    return result;
  }
}

static validateDataTable(listResponse) {

  let result = [];
  let obj = {
    id: {}
  };
  if (listResponse) {
    console.log(listResponse)
     listResponse.forEach((element, index)=> {
    return result;
  })
}
}

  static filterValues(listResponse, objFiltered) {
    let result = [];
    let arrayFields = []
    if (listResponse && objFiltered) {
      Object.keys(objFiltered).forEach((element, index) => {
        arrayFields.push({key: element, value: Object.values(objFiltered)[index]})
      });
      if (arrayFields) {
        arrayFields = arrayFields.filter(field => (field && field.key && field.value))
        arrayFields.forEach(field => {
          listResponse.forEach(resp => {
              if (resp[field.key] === field.value) {
               result.push(resp)
              }
          })
        })
      }
    }
    return result;
  }

  static validateBrandToModel(brand) {
    let result = {
      position: null,
      code_commerce: "",
      code: "",
      name: "",
      enabled: false,
    }

    if (brand) {
      result.code_commerce = '';
      result.position = brand.position;
      result.code = brand.code;
      result.name = brand.name;
      result.enabled = true;
    }

    return result;
  }

  static validateDataTableUser(listResponse) {
    let result = [];
    let obj = {
      // id: {
      //   value:'',
      //   hidden:false
      // },
      name: {
        value:'',
      },
      lastName:{
        value:'',
      },
      email:{
        value:'',
        type:'link',
        link:'users/edit/'
      },
      role:{value: ''},
      state:{value: ''}
    };
    if (listResponse) {
      // console.log(listResponse)
      // console.log(obj)
      listResponse.forEach((element, index)=> {
        // if (obj && obj.id) obj.id.value = element.merchant_batch_id;
        if (obj && obj.name) obj.name.value = element.name;
        if (obj && obj.lastName) obj.lastName.value = element.lastname;
        if (obj && obj.email) {
          obj.email.value = element.email;
          obj.email.link = `users/edit/${element.user_key}`
        }
        if (obj && obj.role) obj.role.value = element.role;
        if (obj && obj.state) obj.state.value = element.state;
        // console.log(obj)
        result.push(obj);
        obj = {
          // id: {
          //   value:'',
          //   hidden:false
          // },
          name: {
            value:'',
          },
          lastName:{
            value:''
          },
          email:{
            value:'',
            type:'link',
            link:'users/edit/'
          },
          role:{value: ''},
          state:{value: ''}
      }
        return obj
    })
    return result;
    }
  }
  static changeRoutesToProd() {
    let AUTH_USER = "";
    let GET_AUTH_USER = "";
    let PUT_AUTH_USER = "";
    let POST_RESET_PASSWORD = "";
    let REGISTER_MERCHANT = ""
    let GET_URL_UPLOAD_FILE = "";
    let ANALYTICS_KEY = "";
    let GET_SESSION_STATUS = "";

    AUTH_USER = process.env.REACT_APP_POST_AUTH
    GET_AUTH_USER = process.env.REACT_APP_GET_AUTH
    PUT_AUTH_USER = process.env.REACT_APP_PUT_AUTH
    POST_RESET_PASSWORD = process.env.REACT_APP_POST_RECOVERY
    ANALYTICS_KEY = process.env.REACT_APP_ANALYTICS_KEY
    REGISTER_MERCHANT = process.env.REACT_APP_POST_MERCHANT
    GET_URL_UPLOAD_FILE = process.env.REACT_APP_POST_MERCHANT_BATCH
    GET_SESSION_STATUS = process.env.REACT_APP_GET_SESSION_STATUS

    const aux = {
      auth_user: AUTH_USER,
      get_auth_user: GET_AUTH_USER,
      put_auth_user: PUT_AUTH_USER,
      post_auth_user: POST_RESET_PASSWORD,
      register_merchant: REGISTER_MERCHANT,
      get_url_upload_file: GET_URL_UPLOAD_FILE,
      analytics_key: ANALYTICS_KEY,
      get_session_status: GET_SESSION_STATUS
    }
    return aux
  }
}

export default Transform;
